import React, { useRef, useEffect, useCallback } from 'react'
import { getBooking } from 'actions/booking'
import { getSettings } from 'actions/setting'
import useApi from 'hooks/useApi'
import Confirmation from 'components/Confirmation'
import { CloudDownloadTwoTone } from '@material-ui/icons'
import { IconButton } from '@material-ui/core'
import html2canvas from 'html2canvas'
import { ANDROID_DOWNLOAD_CONFIRMATION, CONFIRMATION_AUTO_DOWNLOAD } from 'config/app'

const BookingConfirmation = ({ bookingId, location: { search } }) => {
  const [booking, settings] = useApi(() => Promise.all([getBooking(bookingId), getSettings()]))
  const ref = useRef()

  const onDownload = useCallback(async () => {
    const canvas = await html2canvas(ref.current)
    const imageData = canvas.toDataURL('image/png')

    if (ANDROID_DOWNLOAD_CONFIRMATION) {
      if (typeof window.Android !== 'undefined' && window.Android !== null) {
        window.Android[ANDROID_DOWNLOAD_CONFIRMATION](
          imageData.replace('data:image/png;base64,', ''),
          `${bookingId}.png`
        )
      }
    } else {
      const link = document.createElement('a')
      link.href = imageData
      link.download = `${bookingId}.png`
      link.click()
    }
  }, [bookingId])

  useEffect(() => {
    if (CONFIRMATION_AUTO_DOWNLOAD && booking) {
      onDownload()
    }
  }, [booking, onDownload])

  return (
    <Confirmation
      booking={booking}
      approx={settings?.approx}
      search={search}
      ref={ref}
      action={
        <IconButton aria-label="download" onClick={onDownload}>
          <CloudDownloadTwoTone fontSize="large" />
        </IconButton>
      }
    />
  )
}

export default BookingConfirmation
